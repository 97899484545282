import * as PusherPushNotifications from '@pusher/push-notifications-web'

export function initPusher () {
  const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
    url: window.pusherTokenAuthUrl
  })
  const beamsClient = new PusherPushNotifications.Client({
    instanceId: window.pusherInstanceId
  })

  beamsClient
    .getUserId()
    .then((pusherUserId) => {
      // check if user allowed push notifications
      if (window.hasPushNotificationsEnabled) {
        // check if Beams client is already set
        if (window.userId === pusherUserId) {
          console.debug('Beams: client already set, pass')
        } else {
          // set Beams client
          beamsClient
            .start()
            .then(() => beamsClient.setUserId(window.userId, beamsTokenProvider))
            .then(() => console.debug('Beams: client has been set'))
            .catch(err => console.error('Beams: could not authenticate:', err))
        }
      } else {
        // user did not allow push notifications
        if (pusherUserId != null) {
          // stop Beams client if it was set
          return beamsClient
            .stop()
            .then(() => console.debug('Beams: client has been stopped'))
            .catch(err => console.error('Beams: could not clear state:', err))
        } else {
          console.debug('Beams: client is not set, pass')
        }
      }
    })
}
